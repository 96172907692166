import { Box, Typography } from "@mui/material";
import NotAuthorized from "components/notAuthorized";
import SearchBox from "components/ui/searchBox";
import useApi from "hooks/useApi";
import { useDebounce } from "hooks/useDebounce";
import usePermissions from "hooks/usePermissions";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ProjectTable from "./ProjectTable";
import TableSkeleton from "components/skeletons/TableSkeleton";

const CustomerProject = () => {
  const permissions = usePermissions("project");
  const id = useSelector((state) => state.auth.user_details?.user?.id);
  const [allProject, setAllProject] = useState([]);
  const [searchText, setSearchText] = useState("");

  const debouncedText = useDebounce(searchText, 1000);
  const { loading, fetchData } = useApi();

  useEffect(() => {
    const getCustomerProject = async () => {
      const endpoint = {
        method: "get",
        url: `/api/customer/project/admission/info/${id}`,
      };
      const result = await fetchData(endpoint, false);
      if (result.success) {
        setAllProject(result.data);
      }
    };
    getCustomerProject();
  }, [debouncedText]);

  return (
    <Box pt="10px">
      {permissions.list ? (
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              p: "10px",
            }}
          >
            <SearchBox
              handleSearch={(text) => setSearchText(text)}
              placeholder={"Search in Project.."}
            />
          </Box>

          {Object.entries(allProject).map(([groupName, rows]) => (
            <Box key={groupName} sx={{ mb: 4 }}>
              <Typography
                variant="h6"
                sx={{
                  p: 2,
                  bgcolor: "primary.main",
                  color: "white !important",
                  borderRadius: "4px 4px 0 0",
                  textTransform: "capitalize",
                }}
              >
                {groupName} : {rows?.[0]?.project_customer_status_name}
              </Typography>
              <ProjectTable
                loading={loading}
                allProject={rows}
                setAllProject={setAllProject}
              />
            </Box>
          ))}
          {loading && (
            <TableSkeleton
              columns={Array(6).fill({})}
              tableHeight="calc(100vh - 200px)"
            />
          )}
        </Box>
      ) : (
        <NotAuthorized />
      )}
    </Box>
  );
};

export default CustomerProject;
