import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import TableSkeleton from "components/skeletons/TableSkeleton";
import moment from "moment";

const ProjectTable = ({ allProject, loading }) => {
  const rows = allProject;
  const columns = [
    { id: "country_name", label: "Country", style: { width: "100px" } },
    { id: "university_name", label: "University", style: { width: "200px" } },
    { id: "subject_name", label: "Subject", style: { width: "200px" } },
    { id: "student_id", label: "Student Id" },
    { id: "start_class", label: "Start class" },
    { id: "education_level", label: "Education Level" },
    { id: "text", label: "Note" },
    {
      id: "status",
      label: "Status",
      style: { width: "100px" },
      align: "center",
    },
  ];

  return (
    <>
      {!loading ? (
        <TableContainer sx={{ height: "100%", overflowX: "auto" }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth, ...column.style }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows?.map((row) => (
                <>
                  <TableRow hover role="checkbox" key={row.id}>
                    {columns.map((column, i) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === "number" ? (
                            column.format(value)
                          ) : column.id === "created_at" ||
                            column.id === "start_class" ? (
                            moment(value).format("ll")
                          ) : column.id === "status" ? (
                            <Box
                              sx={{
                                bgcolor: `${row?.color}15`,
                                color: row?.color,
                                textAlign: "center",
                                padding: "3px 5px",
                                borderRadius: "20px",
                                width: "100px",
                                mt: "5px",
                                fontSize: "13px",
                              }}
                            >
                              {value}
                            </Box>
                          ) : column.id === "preferred_countries" ? (
                            value.map((cn) => cn.name).join(", ") || "-"
                          ) : (
                            value
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <TableSkeleton columns={columns} tableHeight="calc(100vh - 200px)" />
      )}
    </>
  );
};

export default ProjectTable;
